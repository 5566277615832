<!-- 商品列表 -->
<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem;margin-bottom: 0!important;">
		<!-- 头部标签页 -->
		<el-tabs v-model="tabIndex" @tab-click="getList">
			<el-tab-pane :label="tab.name" :key="tabI" v-for="(tab,tabI) in tabbars"></el-tab-pane>
		</el-tabs>


		<!-- 商品列表 -->
		<el-table border class="mt-3" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange" size="mini">
			<el-table-column type="selection" width="45" align="center">
			</el-table-column>
			
			<!-- 商品 -->
			<el-table-column label="签到商品" width="300">
				<template slot-scope="scope">
					<div class="media">
						<img class="mr-3" style="width: 60px;height: 60px;" :src="scope.row.goods.cover">
						<div class="media-body">
							<p class="mt-0 mb-0">{{scope.row.goods.title.slice(0,32)}}</p>
							<p class="mb-0">时间：{{scope.row.create_time}}</p>
						</div>
					</div>
				</template>
			</el-table-column>
			
			<el-table-column align="center" prop="dqcs" width="180" label="当前次数">
			</el-table-column>
			<el-table-column align="center" prop="sxcs" width="180" label="所需次数">
			</el-table-column>
			<el-table-column align="center" prop="qdsj" width="180" label="签到时间">
			</el-table-column>

			<el-table-column prop="jssj" align="center" width="190" label="结束时间">
			</el-table-column>

			<el-table-column prop="useruser" align="center" label="用户" width="160">
				<template slot-scope="scope">
					<span class="text-danger">{{scope.row.useruser.nickname}}</span>
					
				</template>
			</el-table-column>
			
			<!-- 操作 -->
			<el-table-column align="center" label="操作">
				<template slot-scope="scope">
					<div v-if="tab != 'delete'">
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
					</div>
					<div v-else>暂无操作</div>
				</template>
			</el-table-column>
		</el-table>
		<div style="height: 60px;"></div>
		<el-footer class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0;left: 200px;right: 0;z-index: 100;">
			<div style="flex: 1;" class="px-2">
				<el-pagination :current-page="page.current" :page-sizes="page.sizes" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
				 :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</el-footer>

	</div>
</template>

<script>
	import buttonSearch from '@/components/common/button-search.vue'; // 引入 按钮、搜索、高级搜索组件
	import common from '@/common/mixins/common.js'; // 引入 全局共公样式
	import { mapState } from 'vuex'; // 引入 全局 公共数据
	export default {
		mixins: [common], // 引入mixins里的common.js
		inject: ['layout'], // 依赖注入
		// 引入注册
		components: {
			buttonSearch
		},
		data() {
			return {
				preUrl: "goodsqd", // api

				tabIndex: 0,
				tabbars: [
					{name: "全部",key: "all"},
					{name: "进行中",key: "jxz"},
					{name: "已兑换",key: "ydh"},
					{name: "已结束",key: "yjs"},
				],
				form: {
					title: "", //商品名称
					category_id: "" //商品分类
				},
				tableData: [],
				cateOptions: []
			}
		},
		filters: {
			tree(item) {
				if (item.level == 0) {
					return item.name
				}
				let str = ''
				for (let i = 0; i < item.level; i++) {
					str += i == 0 ? '|--' : '--'
				}
				return str + ' ' + item.name;
			}
		},
		// 计算属性
		computed: {
			// 引入store-modules的user模块里面的user(存储用户相关的数据)
			...mapState({
				user: state => state.user.user,
				navBar: state => state.menu.navBar
			}),
			// 订单类型
			tab() {
				return this.tabbars[this.tabIndex].key //拿到key值，用来高级搜索
			},
			params() {
				let str = ''
				for (let key in this.form) {
					let val = this.form[key]
					if (val) {
						str += `&${key}=${this.form[key]}`
					}
				}
				return str
			}
		},
		// 生命周期，调用__getData生成的数据
		created() {

		},
		// 生命周期监听页面
		methods: {
			// 调转基础设置、商品规格、商品属性、媒体设置、商品详情页面
			navigate(name, id) {
				this.$router.push({
					name,
					params: { id }
				})
			},
			// 获取请求列表分页url
			getListUrl() {
				return `/admin/${this.preUrl}/${this.page.current}?limit=${this.page.size}&tab=${this.tab}${this.params}`
			},
			// 获取列表 可修改
			getListResult(e) {
				console.log(this.user);
				this.tableData = e.list
				this.cateOptions = e.cates
			},
			// 上下架
			changeStatus(item) {
				item.status = item.status === 1 ? 0 : 1
			},
			// 清空筛选条件
			clearSearch() {
				this.form = {
					title: "",
					category_id: ""
				}
			},
			// 搜索事件
			searchEvent(e = false) {
				// 简单搜索
				if (typeof e === 'string') {
					this.form.title = e
				}
				// 高级搜索
				this.getList()
			},
			// 删除商品/恢复商品
			doEvent(key) {
				if (this.ids.length === 0) {
					return this.$message({
						type: "error",
						message: "请选择要操作的商品"
					})
				}
				this.showLoading()
				this.axios.post('/admin/goods/' + key, { //删除商品/恢复商品api（restore、destroy区别 删除/回复）
					ids: this.ids
				}, {
					token: true
				}).then(res => {
					this.$message({
						type: "success",
						message: "操作成功"
					})
					this.getList()
					this.hideLoading()
				}).catch(err => {
					this.hideLoading()
				})
			},
			// 上下架
			changeStatus(status) {
				if (this.ids.length === 0) {
					return this.$message({
						type: "error",
						message: "请选择要操作的商品"
					})
				}
				this.showLoading()
				this.axios.post('/admin/goods/changestatus', { //商品上下架api
					ids: this.ids,
					status
				}, {
					token: true
				}).then(res => {
					this.$message({
						type: "success",
						message: "操作成功"
					})
					this.getList()
					this.hideLoading()
				}).catch(err => {
					this.hideLoading()
				})
			},
			// 审核商品 ischeck审核 1同意 2拒绝
			checkGoods(item, ischeck) {
				this.layout.showLoading()
				this.axios.post('/admin/goods/' + item.id + '/check', { //审核商品 api
					ischeck
				}, {
					token: true
				}).then(res => {
					this.getList()
					this.$message({
						type: "success",
						message: "操作成功"
					})
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			}
		},
	}
</script>

<style>
</style>
